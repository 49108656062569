import { useCallback } from "react";
import axios from "src/utils/axios";

export function usePublicChargersGetAll() {
  const markersGetAll = useCallback(async () => {
    let path = `assets/evses/mapMarkers`;

    return axios
      .get(path)
      .then((response) => {
        return response.data;
      })
      .catch((err) => err);
  }, []);

  return {
    markersGetAll,
  };
}
